@import url('https://omframehouse.com.np/static/fonts/montserrat/Montserrat-Regular.ttf');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;500;700&display=swap');
*{
  list-style-type: none;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
}
a{
  text-decoration: none !important;
}
body {
  margin: 0;
  max-width: 100% !important;
  overflow-x: hidden !important;
}
.col-1{
  color: #e75430 !important;
}
.col-2{
  color:#212121 !important;
}
.fw-5{
  font-weight: 700 !important;
}
.fw-2{
  font-weight: 200 !important;
}
.primary_btn{
  max-width: 250px;
  text-align: center;
  padding: 10px 15px;
  color: white;
  background-color: #e75430;
  transition: 0.2s;
  border: 2px solid #e75430;
}
.primary_btn a{
  color: white;
  text-decoration: none;
}
.primary_btn:hover{
  background-color: transparent;
  border:2px solid #e75430;
}
.primary_btn:hover a{
  color: #e75430;
}
.fs-10{
  font-size: 10px !important;
}
.fs-12{
  font-size: 12px !important;
}
.fs-14{
  font-size: 14px !important;
}
.fs-16{
  font-size: 16px;
}
.fs-25{
  font-size: 25px;
}
.sec_btn{
  background-color: rgb(33, 33, 33);
  color: white;max-width: 250px;
  text-align: center;
  padding: 8px 18px;
  transition: 0.2s;
  border: 2px solid rgb(33, 33, 33);
  cursor: pointer;
}
.sec_btn a{
  color: white;
}
.sec_btn:hover{
  background-color: transparent;
  color: rgb(33, 33, 33);
  border: 2px solid rgb(33, 33, 33);
}
.sec_btn:hover a{
  color: rgb(33, 33, 33);
}
.navbar-light .navbar-toggler{
  border: none !important;
}
.css-13xfq8m-MuiTabPanel-root{
  padding: 0 !important;
  margin-top: 12px;
}

@media only screen and (min-width: 570px){

  .File{
    width: 500px !important;
}

}


/* function changeMount(id){
  var a = (id)
  if(a === '1'){
      console.log('white')
      $("#if_mount").css({
          'border': '15px solid white',
      });
  }
  else if(a === '2'){
      console.log('black')
      $("#if_mount").css({
          'border': '30px solid black',
      });
  }
  else if(a === '3'){
      console.log('maroon')
      $("#if_mount").css({
          'border': '30px solid rgb(82, 14, 31)',
      });
  }
  else{
      $("#if_mount").css({
          'border': 'none',
      });
  }
}

function borderChange(value){
  document.getElementById('value').innerHTML = value + 'inch';
} */
